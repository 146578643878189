import React, { CSSProperties, FC, useEffect, useState } from "react"
import { DatePicker } from "antd"
import { Dayjs } from "dayjs"

export const SERVER_DATE_FORMAT = "YYYY-MM-DD"
export const DISPLAY_DATE_FORMAT = "DD/MM/YYYY"

interface AppMetierDateInputProps {
  value: Dayjs | undefined
  onChange?: (selectedDate: Dayjs) => void
  onCleared?: () => void
  disabled?: boolean
  minDate?: Dayjs
  maxDate?: Dayjs
  initialMonth?: Dayjs
  allowClear?: boolean
  inputReadOnly?: boolean
  style?: CSSProperties
  placeholder?: string
  status?: "" | "warning" | "error" | undefined
}

export const AppMetierDateInput: FC<AppMetierDateInputProps> = ({
  value,
  onChange,
  disabled,
  minDate,
  maxDate,
  initialMonth,
  allowClear,
  inputReadOnly = false,
  style,
  placeholder,
  onCleared,
  status
}) => {
  const [valueState, setValueState] = useState(value)

  useEffect(() => {
    setValueState(value)
  }, [value])

  return (
    <DatePicker
      placeholder={placeholder ?? DISPLAY_DATE_FORMAT}
      value={valueState}
      onChange={selectedDate => {
        if (selectedDate !== null) {
          setValueState(selectedDate)
          if (onChange) {
            onChange(selectedDate)
          }
        } else if (allowClear && onCleared) {
          onCleared()
        }
      }}
      format={DISPLAY_DATE_FORMAT}
      disabled={disabled}
      disabledDate={d =>
        !d || (!!minDate && d.isSameOrBefore(minDate)) || (!!maxDate && d.isSameOrAfter(maxDate))
      }
      defaultPickerValue={initialMonth}
      allowClear={allowClear}
      inputReadOnly={inputReadOnly}
      style={style}
      status={status}
    />
  )
}
