import { useAppContext } from "../config/context"
import { useQuery } from "@tanstack/react-query"
import { extract } from "../client/backend-client/AxiosUtils"
import { useHeaders } from "./axios-headers"

export enum ReferenceCode {
  STATUT_JURIDIQUE = "STATUT_JURIDIQUE",
  SECTEUR_ACTIVITE = "SECTEUR_ACTIVITE",
  STATUT_COOPERATIF = "STATUT_COOPERATIF",
  AGREMENT_ESUS = "AGREMENT_ESUS",
  RECOMMANDEUR = "RECOMMANDEUR",
  TYPE_RECOMMANDEUR = "TYPE_RECOMMANDEUR",
  PHASE_PROJET = "PHASE_PROJET",
  TERRITOIRE_FRAGILE = "TERRITOIRE_FRAGILE",
  OBJET_FINANCEMENT = "OBJET_FINANCEMENT",
  OBJET_PROJET = "OBJET_PROJET",
  FINALITE_SOCIETALE = "FINALITE_SOCIETALE",
  CODE_APE = "CODE_APE",
  TYPE_ROLE = "TYPE_ROLE",
  CIVILITE = "CIVILITE",
  SITUATION_MATRIMONIALE = "SITUATION_MATRIMONIALE",
  SITUATION_PROFESSIONNELLE = "SITUATION_PROFESSIONNELLE",
  TYPE_REVENU = "TYPE_REVENU",
  NIVEAU_ETUDE = "NIVEAU_ETUDE",
  BENEFICIAIRE_MINIMA_SOCIAUX = "BENEFICIAIRE_MINIMA_SOCIAUX",
  ZONAGE_EPCI = "ZONAGE_EPCI"
}

export const useGetReference = (referenceCode: ReferenceCode) => {
  const { referenceResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data } = useQuery({
    queryKey: ["reference", referenceCode],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return referenceResourceApi.getReferenceByCode({ referenceCode }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération de la référence ${referenceCode}`
    }
  })

  return {
    isLoading,
    data
  }
}
