/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface HolderRequest
 */
export interface HolderRequest {
  /**
   *
   * @type {string}
   * @memberof HolderRequest
   */
  dateEntreeAccompagnement?: string
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  majeurInscritMissionLocale: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  inscritCapEmploi: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  sortantAse: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  parentIsole: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  personneSansDomicileHebergee: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  personneRecemmentArriveeFrance: boolean
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  accompagnementPostCreation: boolean
  /**
   *
   * @type {string}
   * @memberof HolderRequest
   */
  precisionNiveauQuatre?: HolderRequestPrecisionNiveauQuatreEnum
  /**
   *
   * @type {string}
   * @memberof HolderRequest
   */
  prescriptionSortie?: HolderRequestPrescriptionSortieEnum
  /**
   *
   * @type {string}
   * @memberof HolderRequest
   */
  motifSortie?: HolderRequestMotifSortieEnum
  /**
   *
   * @type {string}
   * @memberof HolderRequest
   */
  dateSortie?: string
  /**
   *
   * @type {boolean}
   * @memberof HolderRequest
   */
  parcoursAccesPlus: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum HolderRequestPrecisionNiveauQuatreEnum {
  SORTIE = "SORTIE",
  JEUNE_DIPLOME = "JEUNE_DIPLOME",
  DIFFICULTE_PERSO = "DIFFICULTE_PERSO",
  PRECARITE_ECONOMIQUE = "PRECARITE_ECONOMIQUE",
  MANQUE_CRITERE = "MANQUE_CRITERE",
  AUTRE = "AUTRE"
}
/**
 * @export
 * @enum {string}
 */
export enum HolderRequestPrescriptionSortieEnum {
  VERS_OPERATEUR_ACC = "VERS_OPERATEUR_ACC",
  VERS_SERVICE_PUB = "VERS_SERVICE_PUB",
  AUTRE = "AUTRE"
}
/**
 * @export
 * @enum {string}
 */
export enum HolderRequestMotifSortieEnum {
  PLUS_DE_CONTACT = "PLUS_DE_CONTACT",
  RENONCE = "RENONCE",
  CONTRAINTES_FAM = "CONTRAINTES_FAM",
  TROUVE_EMPLOI = "TROUVE_EMPLOI",
  FORMATION_PRO = "FORMATION_PRO",
  NE_CONVIENT_PAS = "NE_CONVIENT_PAS",
  DECALE_PROJET = "DECALE_PROJET",
  PROBLEMES_PERSO = "PROBLEMES_PERSO",
  AUTRE = "AUTRE"
}
