import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { Card, Descriptions, Divider, Space, Typography } from "antd"
import { ProjectContext } from "../../ProjectWrapper"
import {
  CodeLibelle,
  StructureDetailed,
  UpdateStructureRequest
} from "../../../client/backend-client/generated"
import { ReferenceSelect } from "../../../components/AppMetierSelect/ReferenceSelect"
import { ReferenceCode } from "../../../queries/ReferenceQueries"
import { AppMetierSwitch } from "../../../components/AppMetierSwitch"
import { AppMetierTextInput } from "../../../components/AppMetierTextInput/AppMetierTextInput"
import _ from "lodash"
import { useDebouncedCallback } from "use-debounce"
import {
  getFlushEffectCallback,
  LONG_UPDATE_DEBOUNCE_DEFAULT_DELAY
} from "../../../utils/DebounceUtils"
import { toStructureRequest, useUpdateStructure } from "../../../queries/StructureQueries"
import {
  AppMetierDateInput,
  SERVER_DATE_FORMAT
} from "../../../components/AppMetierDateInput/AppMetierDateInput"
import dayjs from "dayjs"
import { formatDayjs } from "../../../utils/DayjsUtils"
import { AppMetierTitle } from "../../../components/AppMetierTitle/AppMetierTitle"
import "./StructureForm.scss"
import { RequiredFieldMessage } from "../project/ProjectForm"
import { CodeAPEAutoComplete } from "./CodeAPEAutoComplete"
import { AddressComponent } from "./AddressComponent"
import { NumberInputWithSteps } from "../../../components/NumberInputWithSteps/NumberInputWithSteps"
import { Suffixes } from "../../../utils/suffixes"

const { Text } = Typography

const LABEL_STYLE = { width: "30%", minWidth: "10rem" }
const DESCRIPTION_ITEM_CLASSNAME = "structure-description-item"

export const StructureForm: FC<{
  structure: StructureDetailed
}> = ({ structure }) => {
  const { structureId, projectId } = useContext(ProjectContext)
  const updateStructureMutation = useUpdateStructure(structureId, projectId)

  const structureToRequest = (structureProp: StructureDetailed): UpdateStructureRequest => ({
    nom: structureProp.nom,
    nomCommercial: structureProp.nomCommercial,
    email: structureProp.email,
    telephone: structureProp.telephone,
    siret: structureProp.siret,
    statutJuridique: structureProp.statutJuridique,
    dateCreation: structureProp.dateCreation,
    codeApe: structureProp.codeApe,
    finaliteSocietale: structureProp.finaliteSocietale,
    statutCooperatif: structureProp.statutCooperatif,
    ess: structureProp.ess,
    agrementEsus: structureProp.agrementEsus,
    adresse: structureProp.adresse,
    dateDemandeEsus: structureProp.dateDemandeEsus,
    dateObtentionEsus: structureProp.dateObtentionEsus,
    dureeEsus: structureProp.dureeEsus
  })

  const refs = useRef({
    initialStructureRequest: structureToRequest(structure)
  })

  const [structureRequest, setStructureRequest] = useState<UpdateStructureRequest>(
    refs.current.initialStructureRequest
  )

  const debouncedOnStructureRequestChange = useDebouncedCallback(() => {
    if (isStructureRequestFilled()) {
      updateStructureMutation.mutate(
        toStructureRequest(refs.current.initialStructureRequest, structureRequest)
      )
    }
  }, LONG_UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(debouncedOnStructureRequestChange, [structureRequest])
  useEffect(getFlushEffectCallback(debouncedOnStructureRequestChange), [
    debouncedOnStructureRequestChange
  ])

  const isStructureRequestFilled = () => {
    return (
      !_.isEqual(structureRequest, {}) &&
      !_.isEqual(refs.current.initialStructureRequest, structureRequest) &&
      structureRequest.nom &&
      !isErrorFutureCreationDate()
    )
  }

  const isEssFixed = (structureRequest: UpdateStructureRequest) => {
    const { statutJuridique, statutCooperatif } = structureRequest
    return (
      statutJuridique?.code === "ASSO" ||
      statutCooperatif?.code === "SCIC" ||
      statutCooperatif?.code === "SCOP"
    )
  }

  /**
   * Prend une structureRequest en entrée, force ESS à oui si besoin est
   */
  const handleEss = (structureRequest: UpdateStructureRequest): UpdateStructureRequest => {
    if (isEssFixed(structureRequest)) {
      return { ...structureRequest, ess: true }
    }
    return structureRequest
  }

  const isErrorFutureCreationDate = () => {
    const { dateCreation } = structureRequest

    if (!dateCreation) {
      return false
    } else {
      return dayjs(dateCreation).isAfter(dayjs())
    }
  }

  return (
    <Card>
      <Space direction="vertical" className="full-width-space">
        <AppMetierTitle level={4}>Structure</AppMetierTitle>
        <Descriptions bordered size="middle" column={1}>
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Dénomination sociale*</Text>}
          >
            <AppMetierTextInput
              maxLength={100}
              inputValue={structureRequest.nom}
              onChange={value => {
                setStructureRequest({ ...structureRequest, nom: value })
              }}
              status={structureRequest.nom ? undefined : "error"}
              name=""
            />
            {!structureRequest.nom && <RequiredFieldMessage />}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Nom commercial</Text>}>
            <AppMetierTextInput
              maxLength={100}
              inputValue={structureRequest.nomCommercial}
              onChange={value => {
                setStructureRequest({
                  ...structureRequest,
                  nomCommercial: value !== "" ? value : undefined
                })
              }}
              name=""
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Téléphone</Text>}>
            <AppMetierTextInput
              maxLength={10}
              pattern={"[0-9]{10}"}
              inputValue={structureRequest.telephone}
              onChange={value => {
                setStructureRequest({
                  ...structureRequest,
                  telephone: value !== "" ? value : undefined
                })
              }}
              name=""
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Email</Text>}>
            <AppMetierTextInput
              maxLength={255}
              inputValue={structureRequest.email}
              onChange={value => {
                setStructureRequest({
                  ...structureRequest,
                  email: value !== "" ? value : undefined
                })
              }}
              name=""
            />
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <Divider />
      <Space direction="vertical" className="full-width-space">
        <AddressComponent
          addressData={structureRequest.adresse}
          labelStyle={LABEL_STYLE}
          showCodeCommune
          setData={value => setStructureRequest({ ...structureRequest, adresse: value })}
          initialAddress={structure.adresse}
        />
      </Space>
      <Divider />
      <Space direction="vertical" className="full-width-space">
        <AppMetierTitle level={4}>Informations légales</AppMetierTitle>
        <Descriptions bordered size="middle" column={1}>
          <Descriptions.Item
            className={DESCRIPTION_ITEM_CLASSNAME}
            labelStyle={LABEL_STYLE}
            label={<Text strong>Date de création</Text>}
          >
            <AppMetierDateInput
              status={isErrorFutureCreationDate() ? "error" : undefined}
              value={
                structureRequest.dateCreation ? dayjs(structureRequest.dateCreation) : undefined
              }
              onChange={value =>
                setStructureRequest({
                  ...structureRequest,
                  dateCreation: value ? formatDayjs(value, SERVER_DATE_FORMAT) : undefined
                })
              }
            />
            {isErrorFutureCreationDate() ? <FutureDateMessage paddingLeft={"10px"} /> : null}
          </Descriptions.Item>
          <Descriptions.Item
            className={DESCRIPTION_ITEM_CLASSNAME}
            labelStyle={LABEL_STYLE}
            label={<Text strong>SIRET</Text>}
          >
            <AppMetierTextInput
              maxLength={14}
              inputValue={structureRequest.siret ?? ""}
              onChange={value => {
                setStructureRequest({ ...structureRequest, siret: value })
              }}
              name=""
            />
          </Descriptions.Item>
          <Descriptions.Item
            className={DESCRIPTION_ITEM_CLASSNAME}
            labelStyle={LABEL_STYLE}
            label={<Text strong>Code APE</Text>}
          >
            <CodeAPEAutoComplete
              codeLibelle={structureRequest.codeApe}
              allowClear
              onChange={(value?: CodeLibelle) => {
                setStructureRequest({ ...structureRequest, codeApe: value })
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className={DESCRIPTION_ITEM_CLASSNAME}
            labelStyle={LABEL_STYLE}
            label={<Text strong>Secteur d'activité</Text>}
          >
            {structure.secteurActivite?.libelle ?? ""}
          </Descriptions.Item>
          <Descriptions.Item
            className={DESCRIPTION_ITEM_CLASSNAME}
            labelStyle={LABEL_STYLE}
            label={<Text strong>Finalité sociétale</Text>}
          >
            <ReferenceSelect
              referenceCode={ReferenceCode.FINALITE_SOCIETALE}
              codeLibelle={structureRequest.finaliteSocietale}
              allowClear
              onChange={(value?: CodeLibelle) => {
                setStructureRequest({ ...structureRequest, finaliteSocietale: value })
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className={DESCRIPTION_ITEM_CLASSNAME}
            labelStyle={LABEL_STYLE}
            label={<Text strong>Statut juridique</Text>}
          >
            <ReferenceSelect
              referenceCode={ReferenceCode.STATUT_JURIDIQUE}
              codeLibelle={structureRequest.statutJuridique}
              allowClear
              onChange={(value?: CodeLibelle) => {
                setStructureRequest(handleEss({ ...structureRequest, statutJuridique: value }))
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className={DESCRIPTION_ITEM_CLASSNAME}
            labelStyle={LABEL_STYLE}
            label={<Text strong>Statut coopératif</Text>}
          >
            <ReferenceSelect
              referenceCode={ReferenceCode.STATUT_COOPERATIF}
              codeLibelle={structureRequest.statutCooperatif}
              allowClear
              onChange={(value?: CodeLibelle) => {
                setStructureRequest(handleEss({ ...structureRequest, statutCooperatif: value }))
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className={DESCRIPTION_ITEM_CLASSNAME}
            labelStyle={LABEL_STYLE}
            label={<Text strong>Agrément ESUS</Text>}
          >
            <ReferenceSelect
              referenceCode={ReferenceCode.AGREMENT_ESUS}
              codeLibelle={structureRequest.agrementEsus}
              onChange={(value?: CodeLibelle) => {
                let newStructureRequest = { ...structureRequest, agrementEsus: value }

                if (structureRequest.agrementEsus?.code === "PAS_AGR") {
                  newStructureRequest = {
                    ...newStructureRequest,
                    dureeEsus: undefined,
                    dateDemandeEsus: undefined,
                    dateObtentionEsus: undefined
                  }
                }

                setStructureRequest(newStructureRequest)
              }}
            />
          </Descriptions.Item>
          {structureRequest.agrementEsus?.code !== undefined &&
          structureRequest.agrementEsus.code !== "PAS_AGR" ? (
            <>
              <Descriptions.Item
                className={DESCRIPTION_ITEM_CLASSNAME}
                labelStyle={LABEL_STYLE}
                label={<Text strong>Date de demande ESUS</Text>}
              >
                <AppMetierDateInput
                  value={
                    structureRequest.dateDemandeEsus
                      ? dayjs(structureRequest.dateDemandeEsus)
                      : undefined
                  }
                  onChange={value =>
                    setStructureRequest({
                      ...structureRequest,
                      dateDemandeEsus: value ? formatDayjs(value, SERVER_DATE_FORMAT) : undefined
                    })
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item
                className={DESCRIPTION_ITEM_CLASSNAME}
                labelStyle={LABEL_STYLE}
                label={<Text strong>Date d'obtention ESUS</Text>}
              >
                <AppMetierDateInput
                  value={
                    structureRequest.dateObtentionEsus
                      ? dayjs(structureRequest.dateObtentionEsus)
                      : undefined
                  }
                  onChange={value =>
                    setStructureRequest({
                      ...structureRequest,
                      dateObtentionEsus: value ? formatDayjs(value, SERVER_DATE_FORMAT) : undefined
                    })
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item
                className={DESCRIPTION_ITEM_CLASSNAME}
                labelStyle={LABEL_STYLE}
                label={<Text strong>Durée d'agrément (en mois)</Text>}
              >
                <NumberInputWithSteps
                  value={structureRequest.dureeEsus ?? 0}
                  suffix={Suffixes.MONTHS}
                  pluralSuffix={Suffixes.MONTHS}
                  onChange={value => {
                    setStructureRequest({ ...structureRequest, dureeEsus: value })
                  }}
                />
              </Descriptions.Item>
            </>
          ) : null}
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>ESS</Text>}>
            <AppMetierSwitch
              checked={structureRequest.ess ?? false}
              label=""
              onChange={() => {
                setStructureRequest({ ...structureRequest, ess: !structureRequest.ess })
              }}
              checkedInnerLabel="Oui"
              uncheckedInnerLabel="Non"
              disabled={isEssFixed(structureRequest)}
            />
          </Descriptions.Item>
        </Descriptions>
      </Space>
    </Card>
  )
}

export const FutureDateMessage = (style: React.CSSProperties) => (
  <Text type="danger" style={style}>
    La date ne peut pas être postérieure à la date du jour.
  </Text>
)
