import React, { useEffect, useRef, useState } from "react"
import { Descriptions, Space, Typography } from "antd"

import { AppMetierTitle } from "../../../components/AppMetierTitle/AppMetierTitle"
import { useUpdateHolder } from "../../../queries/HolderQueries"
import { useDebouncedCallback } from "use-debounce"
import {
  getFlushEffectCallback,
  LONG_UPDATE_DEBOUNCE_DEFAULT_DELAY
} from "../../../utils/DebounceUtils"
import {
  AppMetierDateInput,
  SERVER_DATE_FORMAT
} from "../../../components/AppMetierDateInput/AppMetierDateInput"
import dayjs from "dayjs"
import { formatDayjs } from "../../../utils/DayjsUtils"
import { AppMetierSwitch } from "../../../components/AppMetierSwitch"
import {
  HolderRequest,
  HolderRequestMotifSortieEnum,
  HolderRequestPrecisionNiveauQuatreEnum,
  HolderRequestPrescriptionSortieEnum
} from "../../../client/backend-client/generated"
import { AppMetierSelect } from "../../../components/AppMetierSelect/AppMetierSelect"
import {
  getMotifSortie,
  getPrecisionNiveau4Label,
  getPrescriptionSortie
} from "../../../utils/EnumToLibelle"
import { RequiredFieldMessage } from "../project/ProjectForm"
import _ from "lodash"

const LABEL_STYLE = { width: "30%" }

export function AccesPlusForm({
  holderId,
  projectId,
  holder,
  onAccesPlusChange
}: {
  holderId: string
  projectId: number
  holder: HolderRequest
  onAccesPlusChange: (val: boolean) => void
}) {
  const refs = useRef({
    initialHolderRequest: holder
  })

  const [holderRequest, setHolderRequest] = useState(refs.current.initialHolderRequest)

  const updateHolderMutation = useUpdateHolder(projectId, holderId)

  const debouncedOnHolderRequestChange = useDebouncedCallback(() => {
    if (isHolderRequestFilled()) {
      updateHolderMutation.mutate(holderRequest)
    }
  }, LONG_UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(() => {
    onAccesPlusChange(holderRequest?.parcoursAccesPlus ?? false)
    debouncedOnHolderRequestChange()
  }, [holderRequest])

  useEffect(getFlushEffectCallback(debouncedOnHolderRequestChange), [
    debouncedOnHolderRequestChange
  ])

  const isHolderRequestFilled = () => {
    return (
      !_.isEqual(holderRequest, {}) && !_.isEqual(refs.current.initialHolderRequest, holderRequest)
    )
  }

  return (
    <Space direction="vertical" className="full-width-space">
      <AppMetierTitle level={4}>Informations Accès +</AppMetierTitle>
      <Descriptions bordered size="middle" column={1}>
        <Descriptions.Item
          labelStyle={LABEL_STYLE}
          label={<Typography.Text strong>Parcours Accès + *</Typography.Text>}
        >
          <AppMetierSwitch
            checked={holderRequest?.parcoursAccesPlus ?? false}
            label=""
            onChange={() => {
              setHolderRequest({
                ...holderRequest,
                parcoursAccesPlus: !holderRequest?.parcoursAccesPlus
              })
            }}
            checkedInnerLabel="Oui"
            uncheckedInnerLabel="Non"
          />
        </Descriptions.Item>
        {holderRequest.parcoursAccesPlus && (
          <>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Date d'entrée en accompagnement*</Typography.Text>}
            >
              <AppMetierDateInput
                status={holderRequest?.dateEntreeAccompagnement ? undefined : "error"}
                value={
                  holderRequest?.dateEntreeAccompagnement
                    ? dayjs(holderRequest?.dateEntreeAccompagnement)
                    : undefined
                }
                onChange={value =>
                  setHolderRequest({
                    ...holderRequest,
                    dateEntreeAccompagnement: value
                      ? formatDayjs(value, SERVER_DATE_FORMAT)
                      : undefined
                  })
                }
                onCleared={() => {
                  setHolderRequest({ ...holderRequest, dateEntreeAccompagnement: undefined })
                }}
                allowClear
                style={{ width: "100%" }}
              />
              {!holderRequest.dateEntreeAccompagnement && <RequiredFieldMessage />}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Majeur inscrit à une mission locale</Typography.Text>}
            >
              <AppMetierSwitch
                checked={holderRequest?.majeurInscritMissionLocale ?? false}
                label=""
                onChange={() => {
                  setHolderRequest({
                    ...holderRequest,
                    majeurInscritMissionLocale: !holderRequest?.majeurInscritMissionLocale
                  })
                }}
                checkedInnerLabel="Oui"
                uncheckedInnerLabel="Non"
              />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Inscrit à Cap Emploi </Typography.Text>}
            >
              <AppMetierSwitch
                checked={holderRequest?.inscritCapEmploi ?? false}
                label=""
                onChange={() => {
                  setHolderRequest({
                    ...holderRequest,
                    inscritCapEmploi: !holderRequest?.inscritCapEmploi
                  })
                }}
                checkedInnerLabel="Oui"
                uncheckedInnerLabel="Non"
              />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Sortant de l'ASE </Typography.Text>}
            >
              <AppMetierSwitch
                checked={holderRequest?.sortantAse ?? false}
                label=""
                onChange={() => {
                  setHolderRequest({
                    ...holderRequest,
                    sortantAse: !holderRequest?.sortantAse
                  })
                }}
                checkedInnerLabel="Oui"
                uncheckedInnerLabel="Non"
              />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Parent isolé</Typography.Text>}
            >
              <AppMetierSwitch
                checked={holderRequest?.parentIsole ?? false}
                label=""
                onChange={() => {
                  setHolderRequest({
                    ...holderRequest,
                    parentIsole: !holderRequest?.parentIsole
                  })
                }}
                checkedInnerLabel="Oui"
                uncheckedInnerLabel="Non"
              />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Personne sans hébergement/hébergée</Typography.Text>}
            >
              <AppMetierSwitch
                checked={holderRequest?.personneSansDomicileHebergee ?? false}
                label=""
                onChange={() => {
                  setHolderRequest({
                    ...holderRequest,
                    personneSansDomicileHebergee: !holderRequest?.personneSansDomicileHebergee
                  })
                }}
                checkedInnerLabel="Oui"
                uncheckedInnerLabel="Non"
              />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Personne récemment arrivée en France</Typography.Text>}
            >
              <AppMetierSwitch
                checked={holderRequest?.personneRecemmentArriveeFrance ?? false}
                label=""
                onChange={() => {
                  setHolderRequest({
                    ...holderRequest,
                    personneRecemmentArriveeFrance: !holderRequest?.personneRecemmentArriveeFrance
                  })
                }}
                checkedInnerLabel="Oui"
                uncheckedInnerLabel="Non"
              />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Accompagnement Post Création</Typography.Text>}
            >
              <AppMetierSwitch
                checked={holderRequest?.accompagnementPostCreation ?? false}
                label=""
                onChange={() => {
                  setHolderRequest({
                    ...holderRequest,
                    accompagnementPostCreation: !holderRequest?.accompagnementPostCreation
                  })
                }}
                checkedInnerLabel="Oui"
                uncheckedInnerLabel="Non"
              />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Précision si niveau 4</Typography.Text>}
            >
              <AppMetierSelect<HolderRequestPrecisionNiveauQuatreEnum>
                value={holderRequest.precisionNiveauQuatre}
                status={holderRequest?.precisionNiveauQuatre ? undefined : "error"}
                options={Object.values(HolderRequestPrecisionNiveauQuatreEnum)}
                valueToSelectItem={value => ({
                  value: value,
                  label: getPrecisionNiveau4Label(value)
                })}
                onChange={val => {
                  setHolderRequest({
                    ...holderRequest,
                    precisionNiveauQuatre: val
                  })
                }}
              />
              {!holderRequest.precisionNiveauQuatre && <RequiredFieldMessage />}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Date d'abandon / sortie</Typography.Text>}
            >
              <AppMetierDateInput
                value={holderRequest?.dateSortie ? dayjs(holderRequest?.dateSortie) : undefined}
                status={holderRequest?.dateSortie ? undefined : "error"}
                onChange={value =>
                  setHolderRequest({
                    ...holderRequest,
                    dateSortie: value ? formatDayjs(value, SERVER_DATE_FORMAT) : undefined
                  })
                }
                onCleared={() => {
                  setHolderRequest({ ...holderRequest, dateSortie: undefined })
                }}
                allowClear
                style={{ width: "100%" }}
              />
              {!holderRequest.dateSortie && <RequiredFieldMessage />}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Motif d'abandon / sortie</Typography.Text>}
            >
              <AppMetierSelect<HolderRequestMotifSortieEnum>
                value={holderRequest.motifSortie}
                options={Object.values(HolderRequestMotifSortieEnum)}
                valueToSelectItem={value => ({
                  value: value,
                  label: getMotifSortie(value)
                })}
                onChange={val => {
                  setHolderRequest({
                    ...holderRequest,
                    motifSortie: val
                  })
                }}
                status={holderRequest?.motifSortie ? undefined : "error"}
              />
              {!holderRequest.motifSortie && <RequiredFieldMessage />}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Typography.Text strong>Prescription en cas de sortie</Typography.Text>}
            >
              <AppMetierSelect<HolderRequestPrescriptionSortieEnum>
                value={holderRequest.prescriptionSortie}
                options={Object.values(HolderRequestPrescriptionSortieEnum)}
                valueToSelectItem={value => ({
                  value: value,
                  label: getPrescriptionSortie(value)
                })}
                onChange={val => {
                  setHolderRequest({
                    ...holderRequest,
                    prescriptionSortie: val
                  })
                }}
                status={holderRequest?.prescriptionSortie ? undefined : "error"}
              />
              {!holderRequest.prescriptionSortie && <RequiredFieldMessage />}
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </Space>
  )
}
