import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { Card, Descriptions, Divider, Space, Typography } from "antd"
import {
  CodeLibelle,
  NoubaHolder,
  UpdateContactRoleBeneficiaireRequest,
  UpdateHolderRequest
} from "../../../client/backend-client/generated"
import { ReferenceSelect } from "../../../components/AppMetierSelect/ReferenceSelect"
import { ReferenceCode } from "../../../queries/ReferenceQueries"
import dayjs from "dayjs"
import { formatDayjs } from "../../../utils/DayjsUtils"
import { AppMetierTextInput } from "../../../components/AppMetierTextInput/AppMetierTextInput"
import {
  AppMetierDateInput,
  SERVER_DATE_FORMAT
} from "../../../components/AppMetierDateInput/AppMetierDateInput"
import { AppMetierSwitch } from "../../../components/AppMetierSwitch"
import TextArea from "antd/es/input/TextArea"
import { AppMetierTitle } from "../../../components/AppMetierTitle/AppMetierTitle"
import {
  toContactRoleBeneficiaireRequest,
  toHolderRequest,
  useUpdateContactRoleBeneficiaire,
  useUpdateHolder
} from "../../../queries/HolderQueries"
import { ProjectContext } from "../../ProjectWrapper"
import { useDebouncedCallback } from "use-debounce"
import {
  getFlushEffectCallback,
  LONG_UPDATE_DEBOUNCE_DEFAULT_DELAY
} from "../../../utils/DebounceUtils"
import _ from "lodash"
import { RequiredFieldMessage } from "../project/ProjectForm"
import { AddressComponent } from "../structure/AddressComponent"

const { Text } = Typography

const LABEL_STYLE = { width: "30%" }

const JOB_SEEKER_CODE = "DMND_EMPL"
const SOCIAL_MINIMA_CODE = "BNF_MIN_SO"
const SALARY_OR_ASSIMILATED = "SLR_ASML"

export const HolderForm: FC<{
  holderId: string
  holder: NoubaHolder
}> = ({ holderId, holder }) => {
  const { projectId } = useContext(ProjectContext)

  const updateContactMutation = useUpdateContactRoleBeneficiaire(projectId, holderId)
  const updateHolderMutation = useUpdateHolder(projectId, holderId)

  const holderToContactRequest = (holder: NoubaHolder): UpdateContactRoleBeneficiaireRequest => ({
    email: holder.email,
    phoneNumber1: holder.phoneNumber1,
    phoneNumber2: holder.phoneNumber2,
    jobFunction: holder.jobFunction,
    lastName: holder.lastName,
    preferredName: holder.preferredName,
    firstName: holder.firstName,
    dateOfBirth: holder.dateOfBirth,
    civility: holder.civility,
    address: {
      adresse1: holder?.address?.address1,
      adresse2: holder?.address?.address2,
      codePostal: holder?.address?.postCode,
      ville: holder?.address?.city,
      codeCommune: holder?.address?.districtCode,
      departementCode: holder?.address?.departmentCode,
      code: holder?.address?.code
    }
  })

  const holderToHolderRequest = (holder: NoubaHolder): UpdateHolderRequest => ({
    maritalStatus: holder.maritalStatus,
    professionalStatus: holder.professionalStatus,
    incomeType: holder.incomeType,
    salaryType: holder.salaryType,
    educationalLevel: holder.educationalLevel,
    poleEmploiInscriptionDate: holder.poleEmploiInscriptionDate,
    minimumSocialBeneficiary: holder.minimumSocialBeneficiary,
    isHandicapped: holder.isHandicapped,
    isGrandePrecarite: holder.isGrandePrecarite,
    isGrandePrecariteForced: holder.isGrandePrecariteForced,
    grandePrecariteComment: holder.grandePrecariteComment,
    isDeceased: holder.isDeceased,
    firstContactDate: holder.firstContactDate
  })

  const refs = useRef({
    initialContactRequest: holderToContactRequest(holder),
    initialHolderRequest: holderToHolderRequest(holder)
  })

  const [contactRequest, setContactRequest] = useState(refs.current.initialContactRequest)
  const [holderRequest, setHolderRequest] = useState(refs.current.initialHolderRequest)

  const debouncedOnContactRequestChange = useDebouncedCallback(() => {
    if (isContactRequestFilled()) {
      updateContactMutation.mutate(
        toContactRoleBeneficiaireRequest(refs.current.initialContactRequest, contactRequest)
      )
    }
  }, LONG_UPDATE_DEBOUNCE_DEFAULT_DELAY)

  const debouncedOnHolderRequestChange = useDebouncedCallback(() => {
    if (isHolderRequestFilled()) {
      updateHolderMutation.mutate(toHolderRequest(refs.current.initialHolderRequest, holderRequest))
    }
  }, LONG_UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(debouncedOnContactRequestChange, [contactRequest])
  useEffect(getFlushEffectCallback(debouncedOnContactRequestChange), [
    debouncedOnContactRequestChange
  ])

  useEffect(debouncedOnHolderRequestChange, [holderRequest])
  useEffect(getFlushEffectCallback(debouncedOnHolderRequestChange), [
    debouncedOnHolderRequestChange
  ])

  const isContactRequestFilled = () => {
    return (
      !_.isEqual(contactRequest, {}) &&
      !_.isEqual(refs.current.initialContactRequest, contactRequest) &&
      contactRequest.firstName &&
      contactRequest.lastName
    )
  }

  const isHolderRequestFilled = () => {
    return (
      !_.isEqual(holderRequest, {}) &&
      !_.isEqual(refs.current.initialHolderRequest, holderRequest) &&
      (holderRequest.professionalStatus?.code !== JOB_SEEKER_CODE ||
        holderRequest.poleEmploiInscriptionDate) &&
      (holderRequest.incomeType?.code !== "ARE" ||
        holderRequest.professionalStatus?.code === JOB_SEEKER_CODE) &&
      (holderRequest.incomeType?.code !== SOCIAL_MINIMA_CODE ||
        holderRequest.minimumSocialBeneficiary)
    )
  }

  return (
    <Card>
      <Space direction="vertical" className="full-width-space">
        <AppMetierTitle level={4}>Identité</AppMetierTitle>
        <Descriptions bordered size="middle" column={1}>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Civilité</Text>}>
            <ReferenceSelect
              referenceCode={ReferenceCode.CIVILITE}
              codeLibelle={contactRequest.civility}
              onChange={(value?: CodeLibelle) => {
                setContactRequest({ ...contactRequest, civility: value })
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Nom*</Text>}>
            <AppMetierTextInput
              maxLength={100}
              inputValue={contactRequest.lastName}
              onChange={value => {
                setContactRequest({ ...contactRequest, lastName: value })
              }}
              status={contactRequest.lastName ? undefined : "error"}
              name=""
            />
            {!contactRequest.lastName && <RequiredFieldMessage />}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Nom d'usage</Text>}>
            <AppMetierTextInput
              maxLength={100}
              inputValue={contactRequest.preferredName}
              onChange={value => {
                setContactRequest({
                  ...contactRequest,
                  preferredName: value !== "" ? value : undefined
                })
              }}
              name=""
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Prénom*</Text>}>
            <AppMetierTextInput
              maxLength={100}
              inputValue={contactRequest.firstName}
              onChange={value => {
                setContactRequest({ ...contactRequest, firstName: value })
              }}
              status={contactRequest.firstName ? undefined : "error"}
              name=""
            />
            {!contactRequest.firstName && <RequiredFieldMessage />}
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Date de naissance</Text>}>
            <AppMetierDateInput
              value={contactRequest.dateOfBirth ? dayjs(contactRequest.dateOfBirth) : undefined}
              onChange={value =>
                setContactRequest({
                  ...contactRequest,
                  dateOfBirth: value ? formatDayjs(value, SERVER_DATE_FORMAT) : undefined
                })
              }
              onCleared={() => {
                setContactRequest({ ...contactRequest, dateOfBirth: undefined })
              }}
              allowClear
              style={{ width: "100%" }}
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Email</Text>}>
            <AppMetierTextInput
              maxLength={100}
              inputValue={contactRequest.email}
              onChange={value => {
                setContactRequest({ ...contactRequest, email: value !== "" ? value : undefined })
              }}
              name=""
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Téléphone 1</Text>}>
            <AppMetierTextInput
              maxLength={10}
              pattern={"[0-9]{10}"}
              inputValue={contactRequest.phoneNumber1}
              onChange={value => {
                setContactRequest({
                  ...contactRequest,
                  phoneNumber1: value !== "" ? value : undefined
                })
              }}
              name=""
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Téléphone 2</Text>}>
            <AppMetierTextInput
              maxLength={10}
              pattern={"[0-9]{10}"}
              inputValue={contactRequest.phoneNumber2}
              onChange={value => {
                setContactRequest({
                  ...contactRequest,
                  phoneNumber2: value !== "" ? value : undefined
                })
              }}
              name=""
            />
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <Divider />
      <Space direction="vertical" className="full-width-space">
        <AddressComponent
          addressData={contactRequest.address}
          labelStyle={LABEL_STYLE}
          showCodeCommune
          setData={value => setContactRequest({ ...contactRequest, address: value })}
          initialAddress={holder.address}
        />
      </Space>
      <Divider />
      <Space direction="vertical" className="full-width-space">
        <AppMetierTitle level={4}>Informations porteur</AppMetierTitle>
        <Descriptions bordered size="middle" column={1}>
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Situation matrimoniale</Text>}
          >
            <ReferenceSelect
              referenceCode={ReferenceCode.SITUATION_MATRIMONIALE}
              codeLibelle={holderRequest.maritalStatus}
              allowClear
              onChange={(value?: CodeLibelle) => {
                setHolderRequest({ ...holderRequest, maritalStatus: value })
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Situation professionnelle</Text>}
          >
            <ReferenceSelect
              referenceCode={ReferenceCode.SITUATION_PROFESSIONNELLE}
              codeLibelle={holderRequest.professionalStatus}
              allowClear
              status={
                holderRequest?.incomeType?.code === "ARE" &&
                holderRequest?.professionalStatus?.code !== JOB_SEEKER_CODE
                  ? "error"
                  : ""
              }
              onChange={(value?: CodeLibelle) => {
                setHolderRequest({
                  ...holderRequest,
                  professionalStatus: value,
                  poleEmploiInscriptionDate: undefined
                })
              }}
            />
            {holderRequest?.incomeType?.code === "ARE" &&
              holderRequest?.professionalStatus?.code !== JOB_SEEKER_CODE && (
                <WrongFieldValueMessage message={"demandeur d'emploi"} />
              )}
          </Descriptions.Item>
          {holderRequest.professionalStatus?.code === JOB_SEEKER_CODE && (
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Text strong>Date d'inscription pôle emploi</Text>}
            >
              <AppMetierDateInput
                value={
                  holderRequest.poleEmploiInscriptionDate
                    ? dayjs(holderRequest.poleEmploiInscriptionDate)
                    : undefined
                }
                onChange={value =>
                  setHolderRequest({
                    ...holderRequest,
                    poleEmploiInscriptionDate: value
                      ? formatDayjs(value, SERVER_DATE_FORMAT)
                      : undefined
                  })
                }
                style={{ width: "100%" }}
              />
              {!holderRequest.poleEmploiInscriptionDate && <RequiredFieldMessage />}
            </Descriptions.Item>
          )}
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Niveau d'étude</Text>}>
            <ReferenceSelect
              referenceCode={ReferenceCode.NIVEAU_ETUDE}
              codeLibelle={holderRequest.educationalLevel}
              allowClear
              onChange={(value?: CodeLibelle) => {
                setHolderRequest({ ...holderRequest, educationalLevel: value })
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Type de revenu</Text>}>
            <ReferenceSelect
              referenceCode={ReferenceCode.TYPE_REVENU}
              codeLibelle={holderRequest.incomeType}
              allowClear
              onChange={(value?: CodeLibelle) => {
                setHolderRequest({
                  ...holderRequest,
                  incomeType: value,
                  minimumSocialBeneficiary: undefined
                })
              }}
            />
          </Descriptions.Item>
          {holderRequest.incomeType?.code === SALARY_OR_ASSIMILATED && (
            <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Type de salaire</Text>}>
              <AppMetierTextInput
                maxLength={100}
                inputValue={holderRequest.salaryType}
                onChange={value => {
                  setHolderRequest({ ...holderRequest, salaryType: value })
                }}
                name=""
              />
            </Descriptions.Item>
          )}
          {holderRequest.incomeType?.code === SOCIAL_MINIMA_CODE && (
            <Descriptions.Item
              labelStyle={LABEL_STYLE}
              label={<Text strong>Bénéficiaire de minima sociaux*</Text>}
            >
              <ReferenceSelect
                referenceCode={ReferenceCode.BENEFICIAIRE_MINIMA_SOCIAUX}
                codeLibelle={holderRequest.minimumSocialBeneficiary}
                onChange={(value?: CodeLibelle) => {
                  setHolderRequest({
                    ...holderRequest,
                    minimumSocialBeneficiary: value,
                    isHandicapped: value?.code === "AAH" ? true : holderRequest.isHandicapped
                  })
                }}
              />
              {!holderRequest.minimumSocialBeneficiary && <RequiredFieldMessage />}
            </Descriptions.Item>
          )}
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Personne en situation de handicap</Text>}
          >
            <AppMetierSwitch
              checked={
                (holderRequest.isHandicapped ||
                  holderRequest.minimumSocialBeneficiary?.code === "AAH") ??
                false
              }
              label=""
              disabled={holderRequest.minimumSocialBeneficiary?.code === "AAH"}
              onChange={() => {
                setHolderRequest({ ...holderRequest, isHandicapped: !holderRequest.isHandicapped })
              }}
              checkedInnerLabel="Oui"
              uncheckedInnerLabel="Non"
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Grande précarité</Text>}>
            <AppMetierSwitch
              checked={holderRequest.isGrandePrecarite ?? false}
              label=""
              disabled={true}
              checkedInnerLabel="Oui"
              uncheckedInnerLabel="Non"
            />
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Forcer la Grande précarité</Text>}
          >
            <AppMetierSwitch
              checked={holderRequest.isGrandePrecariteForced ?? false}
              label=""
              onChange={() => {
                setHolderRequest({
                  ...holderRequest,
                  isGrandePrecariteForced: !holderRequest.isGrandePrecariteForced
                })
              }}
              checkedInnerLabel="Oui"
              uncheckedInnerLabel="Non"
            />
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Commentaire de grande précarité</Text>}
          >
            <TextArea
              value={holderRequest.grandePrecariteComment}
              onChange={value => {
                setHolderRequest({ ...holderRequest, grandePrecariteComment: value.target.value })
              }}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Descriptions.Item>
          <Descriptions.Item labelStyle={LABEL_STYLE} label={<Text strong>Porteur décédé</Text>}>
            <AppMetierSwitch
              checked={holderRequest.isDeceased ?? false}
              label=""
              onChange={() => {
                setHolderRequest({ ...holderRequest, isDeceased: !holderRequest.isDeceased })
              }}
              checkedInnerLabel="Oui"
              uncheckedInnerLabel="Non"
            />
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={LABEL_STYLE}
            label={<Text strong>Date de premier contact</Text>}
          >
            <AppMetierDateInput
              value={
                holderRequest.firstContactDate ? dayjs(holderRequest.firstContactDate) : undefined
              }
              onChange={value =>
                setHolderRequest({
                  ...holderRequest,
                  firstContactDate: value ? formatDayjs(value, SERVER_DATE_FORMAT) : undefined
                })
              }
              style={{ width: "100%" }}
            />
          </Descriptions.Item>
        </Descriptions>
      </Space>
    </Card>
  )
}

export const WrongFieldValueMessage: FC<{ message: string }> = ({ message }) => (
  <Text type="danger">Le champ doit être {message}</Text>
)
