/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { HolderRequest } from "../model"
// @ts-ignore
import { NoubaHolder } from "../model"
// @ts-ignore
import { UpdateContactRoleBeneficiaireRequest } from "../model"
// @ts-ignore
import { UpdateHolderRequest } from "../model"
/**
 * HolderResourceApi - axios parameter creator
 * @export
 */
export const HolderResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get project\'s holder
     * @param {number} projectId
     * @param {string} holderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHolder: async (
      projectId: number,
      holderId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getHolder", "projectId", projectId)
      // verify required parameter 'holderId' is not null or undefined
      assertParamExists("getHolder", "holderId", holderId)
      const localVarPath = `/{projectId}/holders/{holderId}/holder`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"holderId"}}`, encodeURIComponent(String(holderId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get project\'s holders
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoubaHolders: async (
      projectId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getNoubaHolders", "projectId", projectId)
      const localVarPath = `/{projectId}/holders`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update nouba holder\'s contact role
     * @param {number} projectId
     * @param {string} holderId
     * @param {UpdateContactRoleBeneficiaireRequest} updateContactRoleBeneficiaireRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContactRoleBeneficiaire: async (
      projectId: number,
      holderId: string,
      updateContactRoleBeneficiaireRequest: UpdateContactRoleBeneficiaireRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateContactRoleBeneficiaire", "projectId", projectId)
      // verify required parameter 'holderId' is not null or undefined
      assertParamExists("updateContactRoleBeneficiaire", "holderId", holderId)
      // verify required parameter 'updateContactRoleBeneficiaireRequest' is not null or undefined
      assertParamExists(
        "updateContactRoleBeneficiaire",
        "updateContactRoleBeneficiaireRequest",
        updateContactRoleBeneficiaireRequest
      )
      const localVarPath = `/{projectId}/holders/{holderId}/contactRoleBeneficiaire`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"holderId"}}`, encodeURIComponent(String(holderId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateContactRoleBeneficiaireRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Sauvegarde les informations relatives à l\'accès plus du porteur
     * @param {number} projectId
     * @param {string} holderId
     * @param {HolderRequest} holderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateHolder: async (
      projectId: number,
      holderId: string,
      holderRequest: HolderRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateHolder", "projectId", projectId)
      // verify required parameter 'holderId' is not null or undefined
      assertParamExists("updateHolder", "holderId", holderId)
      // verify required parameter 'holderRequest' is not null or undefined
      assertParamExists("updateHolder", "holderRequest", holderRequest)
      const localVarPath = `/{projectId}/holders/{holderId}/holder`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"holderId"}}`, encodeURIComponent(String(holderId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        holderRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update nouba holder
     * @param {number} projectId
     * @param {string} holderId
     * @param {UpdateHolderRequest} updateHolderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNoubaHolder: async (
      projectId: number,
      holderId: string,
      updateHolderRequest: UpdateHolderRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateNoubaHolder", "projectId", projectId)
      // verify required parameter 'holderId' is not null or undefined
      assertParamExists("updateNoubaHolder", "holderId", holderId)
      // verify required parameter 'updateHolderRequest' is not null or undefined
      assertParamExists("updateNoubaHolder", "updateHolderRequest", updateHolderRequest)
      const localVarPath = `/{projectId}/holders/{holderId}/holder-nouba`
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
        .replace(`{${"holderId"}}`, encodeURIComponent(String(holderId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateHolderRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * HolderResourceApi - functional programming interface
 * @export
 */
export const HolderResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HolderResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get project\'s holder
     * @param {number} projectId
     * @param {string} holderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHolder(
      projectId: number,
      holderId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHolder(
        projectId,
        holderId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get project\'s holders
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNoubaHolders(
      projectId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoubaHolder>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNoubaHolders(projectId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update nouba holder\'s contact role
     * @param {number} projectId
     * @param {string} holderId
     * @param {UpdateContactRoleBeneficiaireRequest} updateContactRoleBeneficiaireRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateContactRoleBeneficiaire(
      projectId: number,
      holderId: string,
      updateContactRoleBeneficiaireRequest: UpdateContactRoleBeneficiaireRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoubaHolder>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactRoleBeneficiaire(
        projectId,
        holderId,
        updateContactRoleBeneficiaireRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Sauvegarde les informations relatives à l\'accès plus du porteur
     * @param {number} projectId
     * @param {string} holderId
     * @param {HolderRequest} holderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateHolder(
      projectId: number,
      holderId: string,
      holderRequest: HolderRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateHolder(
        projectId,
        holderId,
        holderRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update nouba holder
     * @param {number} projectId
     * @param {string} holderId
     * @param {UpdateHolderRequest} updateHolderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNoubaHolder(
      projectId: number,
      holderId: string,
      updateHolderRequest: UpdateHolderRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoubaHolder>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoubaHolder(
        projectId,
        holderId,
        updateHolderRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * HolderResourceApi - factory interface
 * @export
 */
export const HolderResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HolderResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get project\'s holder
     * @param {number} projectId
     * @param {string} holderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHolder(projectId: number, holderId: string, options?: any): AxiosPromise<HolderRequest> {
      return localVarFp
        .getHolder(projectId, holderId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get project\'s holders
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoubaHolders(projectId: number, options?: any): AxiosPromise<Array<NoubaHolder>> {
      return localVarFp
        .getNoubaHolders(projectId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update nouba holder\'s contact role
     * @param {number} projectId
     * @param {string} holderId
     * @param {UpdateContactRoleBeneficiaireRequest} updateContactRoleBeneficiaireRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContactRoleBeneficiaire(
      projectId: number,
      holderId: string,
      updateContactRoleBeneficiaireRequest: UpdateContactRoleBeneficiaireRequest,
      options?: any
    ): AxiosPromise<NoubaHolder> {
      return localVarFp
        .updateContactRoleBeneficiaire(
          projectId,
          holderId,
          updateContactRoleBeneficiaireRequest,
          options
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Sauvegarde les informations relatives à l\'accès plus du porteur
     * @param {number} projectId
     * @param {string} holderId
     * @param {HolderRequest} holderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateHolder(
      projectId: number,
      holderId: string,
      holderRequest: HolderRequest,
      options?: any
    ): AxiosPromise<HolderRequest> {
      return localVarFp
        .updateHolder(projectId, holderId, holderRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update nouba holder
     * @param {number} projectId
     * @param {string} holderId
     * @param {UpdateHolderRequest} updateHolderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNoubaHolder(
      projectId: number,
      holderId: string,
      updateHolderRequest: UpdateHolderRequest,
      options?: any
    ): AxiosPromise<NoubaHolder> {
      return localVarFp
        .updateNoubaHolder(projectId, holderId, updateHolderRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getHolder operation in HolderResourceApi.
 * @export
 * @interface HolderResourceApiGetHolderRequest
 */
export interface HolderResourceApiGetHolderRequest {
  /**
   *
   * @type {number}
   * @memberof HolderResourceApiGetHolder
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof HolderResourceApiGetHolder
   */
  readonly holderId: string
}

/**
 * Request parameters for getNoubaHolders operation in HolderResourceApi.
 * @export
 * @interface HolderResourceApiGetNoubaHoldersRequest
 */
export interface HolderResourceApiGetNoubaHoldersRequest {
  /**
   *
   * @type {number}
   * @memberof HolderResourceApiGetNoubaHolders
   */
  readonly projectId: number
}

/**
 * Request parameters for updateContactRoleBeneficiaire operation in HolderResourceApi.
 * @export
 * @interface HolderResourceApiUpdateContactRoleBeneficiaireRequest
 */
export interface HolderResourceApiUpdateContactRoleBeneficiaireRequest {
  /**
   *
   * @type {number}
   * @memberof HolderResourceApiUpdateContactRoleBeneficiaire
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof HolderResourceApiUpdateContactRoleBeneficiaire
   */
  readonly holderId: string

  /**
   *
   * @type {UpdateContactRoleBeneficiaireRequest}
   * @memberof HolderResourceApiUpdateContactRoleBeneficiaire
   */
  readonly updateContactRoleBeneficiaireRequest: UpdateContactRoleBeneficiaireRequest
}

/**
 * Request parameters for updateHolder operation in HolderResourceApi.
 * @export
 * @interface HolderResourceApiUpdateHolderRequest
 */
export interface HolderResourceApiUpdateHolderRequest {
  /**
   *
   * @type {number}
   * @memberof HolderResourceApiUpdateHolder
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof HolderResourceApiUpdateHolder
   */
  readonly holderId: string

  /**
   *
   * @type {HolderRequest}
   * @memberof HolderResourceApiUpdateHolder
   */
  readonly holderRequest: HolderRequest
}

/**
 * Request parameters for updateNoubaHolder operation in HolderResourceApi.
 * @export
 * @interface HolderResourceApiUpdateNoubaHolderRequest
 */
export interface HolderResourceApiUpdateNoubaHolderRequest {
  /**
   *
   * @type {number}
   * @memberof HolderResourceApiUpdateNoubaHolder
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof HolderResourceApiUpdateNoubaHolder
   */
  readonly holderId: string

  /**
   *
   * @type {UpdateHolderRequest}
   * @memberof HolderResourceApiUpdateNoubaHolder
   */
  readonly updateHolderRequest: UpdateHolderRequest
}

/**
 * HolderResourceApi - object-oriented interface
 * @export
 * @class HolderResourceApi
 * @extends {BaseAPI}
 */
export class HolderResourceApi extends BaseAPI {
  /**
   *
   * @summary Get project\'s holder
   * @param {HolderResourceApiGetHolderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HolderResourceApi
   */
  public getHolder(
    requestParameters: HolderResourceApiGetHolderRequest,
    options?: AxiosRequestConfig
  ) {
    return HolderResourceApiFp(this.configuration)
      .getHolder(requestParameters.projectId, requestParameters.holderId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get project\'s holders
   * @param {HolderResourceApiGetNoubaHoldersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HolderResourceApi
   */
  public getNoubaHolders(
    requestParameters: HolderResourceApiGetNoubaHoldersRequest,
    options?: AxiosRequestConfig
  ) {
    return HolderResourceApiFp(this.configuration)
      .getNoubaHolders(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update nouba holder\'s contact role
   * @param {HolderResourceApiUpdateContactRoleBeneficiaireRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HolderResourceApi
   */
  public updateContactRoleBeneficiaire(
    requestParameters: HolderResourceApiUpdateContactRoleBeneficiaireRequest,
    options?: AxiosRequestConfig
  ) {
    return HolderResourceApiFp(this.configuration)
      .updateContactRoleBeneficiaire(
        requestParameters.projectId,
        requestParameters.holderId,
        requestParameters.updateContactRoleBeneficiaireRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Sauvegarde les informations relatives à l\'accès plus du porteur
   * @param {HolderResourceApiUpdateHolderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HolderResourceApi
   */
  public updateHolder(
    requestParameters: HolderResourceApiUpdateHolderRequest,
    options?: AxiosRequestConfig
  ) {
    return HolderResourceApiFp(this.configuration)
      .updateHolder(
        requestParameters.projectId,
        requestParameters.holderId,
        requestParameters.holderRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update nouba holder
   * @param {HolderResourceApiUpdateNoubaHolderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HolderResourceApi
   */
  public updateNoubaHolder(
    requestParameters: HolderResourceApiUpdateNoubaHolderRequest,
    options?: AxiosRequestConfig
  ) {
    return HolderResourceApiFp(this.configuration)
      .updateNoubaHolder(
        requestParameters.projectId,
        requestParameters.holderId,
        requestParameters.updateHolderRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }
}
