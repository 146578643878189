import {
  HolderRequestMotifSortieEnum,
  HolderRequestPrecisionNiveauQuatreEnum,
  HolderRequestPrescriptionSortieEnum,
  NoteAvancementBanqueEnum
} from "../client/backend-client/generated"

export const getAvancementBanqueLabel = (avancementBanque: NoteAvancementBanqueEnum) => {
  switch (avancementBanque) {
    case NoteAvancementBanqueEnum.PAS_DE_CONTACT_OU_DEBUT_DE_LA_DEMANDE_EN_COURS:
      return "Pas de contact ou début de la demande en cours"
    case NoteAvancementBanqueEnum.EN_ATTENTE_REPONSE_DE_LA_BANQUE:
      return "En attente de réponse de la banque"
    case NoteAvancementBanqueEnum.ACCORD_DE_PRINCIPE_ORAL:
      return "Accord de principe oral"
    case NoteAvancementBanqueEnum.ACCORD_ECRIT:
      return "Accord écrit"
    case NoteAvancementBanqueEnum.REFUS_DE_LA_BANQUE:
      return "Refus de la banque"
    case NoteAvancementBanqueEnum.NON_CONCERNE:
      return "Non concerné"
    default:
      return ""
  }
}

export const getPrecisionNiveau4Label = (precision: HolderRequestPrecisionNiveauQuatreEnum) => {
  switch (precision) {
    case HolderRequestPrecisionNiveauQuatreEnum.SORTIE:
      return "Sorti des dispositifs IAE depuis moins de 6 mois"
    case HolderRequestPrecisionNiveauQuatreEnum.JEUNE_DIPLOME:
      return "Jeune diplômé qui n’envoie pas le bon signal"
    case HolderRequestPrecisionNiveauQuatreEnum.DIFFICULTE_PERSO:
      return "Personne en grandes difficultés personnelles"
    case HolderRequestPrecisionNiveauQuatreEnum.PRECARITE_ECONOMIQUE:
      return "Personne en précarité économique"
    case HolderRequestPrecisionNiveauQuatreEnum.MANQUE_CRITERE:
      return "Manque un critère de niveau 2"
    case HolderRequestPrecisionNiveauQuatreEnum.AUTRE:
      return "Autre"
    default:
      return ""
  }
}

export const getMotifSortie = (motif: HolderRequestMotifSortieEnum) => {
  switch (motif) {
    case HolderRequestMotifSortieEnum.PLUS_DE_CONTACT:
      return "Ne se présente pas / plus de contact"
    case HolderRequestMotifSortieEnum.RENONCE:
      return "Renonce à son projet de création"
    case HolderRequestMotifSortieEnum.CONTRAINTES_FAM:
      return "Contraintes familiales"
    case HolderRequestMotifSortieEnum.TROUVE_EMPLOI:
      return "A trouvé un emploi"
    case HolderRequestMotifSortieEnum.FORMATION_PRO:
      return "S'oriente vers une formation professionnelle"
    case HolderRequestMotifSortieEnum.NE_CONVIENT_PAS:
      return "L'accompagnement proposé ne convient pas au porteur ou au projet"
    case HolderRequestMotifSortieEnum.DECALE_PROJET:
      return "Décale son projet dans le temps"
    case HolderRequestMotifSortieEnum.PROBLEMES_PERSO:
      return "Problèmes personnels"
    case HolderRequestMotifSortieEnum.AUTRE:
      return "Autre"
    default:
      return ""
  }
}

export const getPrescriptionSortie = (motif: HolderRequestPrescriptionSortieEnum) => {
  switch (motif) {
    case HolderRequestPrescriptionSortieEnum.VERS_OPERATEUR_ACC:
      return "Vers un opérateur d'accompagnement participant au programme"
    case HolderRequestPrescriptionSortieEnum.VERS_SERVICE_PUB:
      return "Vers un service public de l'emploi"
    case HolderRequestPrescriptionSortieEnum.AUTRE:
      return "Autre orientation"
    default:
      return ""
  }
}
