import React, { CSSProperties, FC, useEffect, useState } from "react"
import { AutoComplete, AutoCompleteProps, Flex, Typography } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { CodeLibelle } from "../../../client/backend-client/generated"
import { ReferenceCode, useGetReference } from "../../../queries/ReferenceQueries"

const { Text } = Typography

interface CodeAPEOption extends DefaultOptionType {
  label: React.ReactNode
  value?: string | number | null
  codeLibelle?: CodeLibelle
  children?: Omit<DefaultOptionType, "children">[]
}

export const CodeAPEAutoComplete: FC<{
  codeLibelle?: CodeLibelle
  onChange: (value: CodeLibelle | undefined) => void
  allowClear?: boolean
  disabled?: boolean
  style?: CSSProperties
}> = ({ codeLibelle, onChange, allowClear, disabled, style }) => {
  const [searchText, setSearchText] = useState<string>()
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([])

  const { isLoading: areReferencesLoading, data: references } = useGetReference(
    ReferenceCode.CODE_APE
  )

  useEffect(() => {
    if (!areReferencesLoading && references) {
      setOptions(
        searchText
          ? references
              .filter(
                ref =>
                  ref.libelle.toLowerCase().includes(searchText.toLowerCase()) ||
                  ref.code.includes(searchText)
              )
              .map(renderCodeAPEOption)
          : references.map(renderCodeAPEOption)
      )
    }
  }, [references, searchText])

  const renderCodeAPEOption = (codeLibelle: CodeLibelle): CodeAPEOption => ({
    value: `${codeLibelle.code} - ${codeLibelle.libelle}`,
    codeLibelle: codeLibelle,
    label: (
      <>
        <Flex align="center" justify="start">
          <Text>
            <Text strong>{codeLibelle.code}</Text> - {codeLibelle.libelle}
          </Text>
        </Flex>
      </>
    )
  })

  return (
    <AutoComplete
      defaultValue={codeLibelle ? `${codeLibelle.code} - ${codeLibelle.libelle}` : undefined}
      searchValue={searchText}
      options={options}
      onSearch={value => setSearchText(value && value.trim() != "" ? value : undefined)}
      onSelect={(_, option: CodeAPEOption) => {
        onChange(option.codeLibelle)
      }}
      style={style ?? { width: "100%" }}
      allowClear={allowClear}
      onClear={() => onChange(undefined)}
      disabled={disabled}
    />
  )
}
