import React, { FC, useState } from "react"
import { Col, Descriptions, Tooltip, Typography } from "antd"
import { AppMetierTextInput } from "../../../components/AppMetierTextInput/AppMetierTextInput"
import "./StructureForm.scss"
import { RegisStructureAddressUpdateRequest } from "../../../client/backend-client/generated/model/regis-structure-address-update-request"
import {
  AddressUpdateRequest,
  RegisStructureAddress
} from "../../../client/backend-client/generated"
import { AppMetierTitle } from "../../../components/AppMetierTitle/AppMetierTitle"
import { AddressAutoComplete } from "../../../components/AddressAutoComplete/AddressAutoComplete"
import { AppMetierSwitch } from "../../../components/AppMetierSwitch"
import { CityAutoComplete } from "../../../components/CityAutoComplete/CityAutoComplete"
import { ReferenceCode, useGetReference } from "../../../queries/ReferenceQueries"
import { QuestionCircleOutlined } from "@ant-design/icons"

const { Text } = Typography

export const AddressComponent: FC<{
  setData: (data: AddressUpdateRequest | AddressUpdateRequest) => void
  addressData: AddressUpdateRequest | AddressUpdateRequest | undefined
  labelStyle: { width?: string; minWidth?: string }
  showCodeCommune: boolean //false pour porteur
  initialAddress?: RegisStructureAddress // Address data from Regis, untouched
}> = ({ addressData, labelStyle, setData, showCodeCommune, initialAddress }) => {
  const [isManual, setIsManual] = useState(false)
  const { isLoading: isEpciReferenceLoading, data: references } = useGetReference(
    ReferenceCode.ZONAGE_EPCI
  )

  const renderAddressField = (
    fieldName: keyof (RegisStructureAddressUpdateRequest | AddressUpdateRequest),
    label: string,
    fixedState?: boolean,
    required: boolean = false
  ) => {
    return (
      <Descriptions.Item
        labelStyle={labelStyle}
        label={
          <Text strong>
            {label}
            {required ? "*" : ""}
          </Text>
        }
      >
        {(fixedState != undefined ? fixedState : isManual) ? (
          <AppMetierTextInput
            maxLength={255}
            inputValue={addressData?.[fieldName] as string}
            onChange={value => {
              setData({
                ...addressData,
                [fieldName]: value !== "" ? value : undefined
              })
            }}
            name=""
            status={required && !addressData?.[fieldName] ? "error" : undefined}
          />
        ) : (
          addressData?.[fieldName]
        )}
      </Descriptions.Item>
    )
  }

  return (
    <div>
      <AppMetierTitle level={4}>Localisation</AppMetierTitle>
      <Descriptions bordered size="middle" column={1}>
        <Descriptions.Item
          labelStyle={labelStyle}
          label={<Text strong>Rechercher une adresse</Text>}
        >
          <AddressAutoComplete
            onChange={value => {
              setData(value)
            }}
            disabled={isManual}
          />
          <Col span={24}>
            <AppMetierSwitch
              checked={isManual}
              label="Saisir une adresse manuellement"
              onChange={() => setIsManual(!isManual)}
              checkedInnerLabel="Oui"
              uncheckedInnerLabel="Non"
            />
          </Col>
        </Descriptions.Item>
        {renderAddressField("adresse1", "Adresse", undefined, true)}
        {renderAddressField("adresse2", "Complément d'adresse", true)}
        {renderAddressField("codePostal", "Code postal", undefined, true)}
        <Descriptions.Item labelStyle={labelStyle} label={<Text strong>Ville*</Text>}>
          {isManual ? (
            <CityAutoComplete
              defaultValue={addressData?.ville}
              onChange={value => {
                setData({
                  ...addressData,
                  ville: value?.ville,
                  codeCommune: value?.codeCommune,
                  code: value?.code
                })
              }}
            />
          ) : (
            addressData?.ville
          )}
        </Descriptions.Item>
        {showCodeCommune ? renderAddressField("codeCommune", "Code commune", false) : null}
        {initialAddress?.epciCode ? (
          <Descriptions.Item
            labelStyle={labelStyle}
            label={
              <div>
                <Text strong>EPCI</Text>
                <Tooltip
                  placement="bottom"
                  title="Établissement public de coopération intercommunale"
                >
                  <QuestionCircleOutlined style={{ fontSize: "1em", paddingLeft: "5px" }} />
                </Tooltip>
              </div>
            }
          >
            {isEpciReferenceLoading
              ? ""
              : references?.find(epci => epci.code === initialAddress.epciCode)?.libelle}
          </Descriptions.Item>
        ) : null}
      </Descriptions>
    </div>
  )
}
