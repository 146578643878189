import React, { FC, useContext } from "react"
import Loader from "../../../components/Loader/Loader"
import { NoteContext } from "../../NotePage"
import { Alert, Tabs } from "antd"
import { HolderForm } from "./HolderForm"

export const HolderPageWrapper: FC = () => {
  const { areHoldersLoading, holders } = useContext(NoteContext)

  if (areHoldersLoading) {
    return <Loader />
  }

  return (
    <>
      {holders &&
        (holders.length > 0 ? (
          <Tabs
            items={holders.map(holder => ({
              label: `${holder.preferredName || holder.lastName || ""} ${holder.firstName || ""}`,
              key: holder.id ?? "",
              children: holder.id && <HolderForm holderId={holder.id} holder={holder} />
            }))}
          />
        ) : (
          <Alert message="Aucun porteur n'a été trouvé sur cette note." type="warning" />
        ))}
    </>
  )
}
