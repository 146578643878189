import React, { FC, useContext } from "react"
import { ProjectContext } from "../../ProjectWrapper"
import Loader from "../../../components/Loader/Loader"
import { StructureForm } from "./StructureForm"
import { useGetStructure } from "../../../queries/StructureQueries"
import { NoteContext } from "../../NotePage"

export const StructurePageWrapper: FC = () => {
  const { structureId } = useContext(ProjectContext)
  const { isLoading: isStructureLoading, data: structure } = useGetStructure(structureId)
  const { areHoldersLoading } = useContext(NoteContext)

  if (isStructureLoading || areHoldersLoading) {
    return <Loader />
  }

  return <>{structure && <StructureForm structure={structure} />}</>
}
